import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'; // slider component


// ProjectCard.js  //helper
const ProjectCard = ({ projectName }) => {
  const projectImagePath = require(`../projects/${projectName}.jpg`);

  return (
    <div className="hm-prj-card">
      <Link to={`/${projectName.toLowerCase()}`}>
        <img src={projectImagePath} alt={projectName} />
        <h3>{projectName}</h3>
      </Link>
    </div>
  );
};



// ProjectSection.js //the component
const ProjectsSection = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Dynamically fetch projects from the "projects" folder
    const projectContext = require.context('../projects', false, /\.js$/);
    const fetchedProjects = projectContext.keys().map((key) => key.replace(/^\.\/(.*)\.\w+$/, '$1'));

    setProjects(fetchedProjects.slice(0, 3)); // Display only the first three projects
  }, []);

  // lets set up carousel, we installed with //npm install...
  // Slick settings for the Slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <h2>Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <React.Suspense key={index} fallback={<div>Loading...</div>}>
            <ProjectCard projectName={project} />
          </React.Suspense>
        ))}
      </Slider>
      
    </>
  );
};

export default ProjectsSection;
