import React from 'react';
import fb_icon from '../genassets/facebook-icon.png'
import tw_icon from '../genassets/twitter-icon.png'
import ig_icon from '../genassets/instagram-icon.png'

const Footer = () => {
  return (
    <footer>
      <div className="copyright">Copyright © 2024 Qenex: Charles</div>
      <div className="social-icons">
        <a href="/" target="_blank" rel="noopener noreferrer">
        <img src={fb_icon} alt="Facebook" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <img src={tw_icon} alt="Twitter" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <img src={ig_icon} alt="Instagram" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
