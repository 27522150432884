import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import {Services} from '../textmaterials/servicesdata'


// ServiceCard object
const ServiceCard = ({ servicedetails }) => {
  return (
    <div className='hm-svc-card'>
      <div className="hm-detail-card">
        <h3>{servicedetails.title}</h3>
        <img src={servicedetails.imagepath} alt={'serviceimage'} />
        <div>{servicedetails.text} </div>
      </div>
      <Link to={'/about'}>... read more</Link>
    </div>
  );
};


// ServicesSection.js //the component
export const MyServicesSection = () => {
  const [Services, setServices] = useState([]);

  useEffect(()=>{
    import('../textmaterials/servicesdata').then((module) => {
      setServices(module.Services);
    });
  },[])

  // check if loaded before rendering after
  if (!Services) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h2>Our Services</h2>
      <div className='hm-svc-grid'>
        {Services.map((service, index) => (
          <React.Suspense key={index} fallback={<div>Loading...</div>}>
            <ServiceCard servicedetails={service} />
          </React.Suspense>
        ))}
      </div>
    </>
  );
};


// ================================================================
// ServiceCard for about
const ServiceCardAbt = ({ servicedetails }) => {
  return (
    <div className="abt-svc-card">
      <h3>{servicedetails.title}</h3>
      <img src={servicedetails.imagepath} alt={'serviceimage'} />
      <div>{servicedetails.text} </div>
    </div>
  );
};


// ServicesSection.js //the component
export const MyServicesSectionAbt = () => {
  const [Services, setServices] = useState([]);

  useEffect(()=>{
    import('../textmaterials/servicesdata').then((module) => {
      setServices(module.Services);
    });
  },[])

  // check if loaded before rendering after
  if (!Services) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h2>Our Services</h2>
      <div className='hm-svc-grid'>
        {Services.map((service, index) => (
          <React.Suspense key={index} fallback={<div>Loading...</div>}>
            <ServiceCardAbt servicedetails={service} />
          </React.Suspense>
        ))}
      </div>
    </>
  );
};
