import React from 'react';
import HouseAnimation from '../othercomponents/houseanim';
import ProjectsSection from '../othercomponents/projectsSection';
import {MyServicesSection} from '../othercomponents/myservices';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <div className='home_section_one'>
        <h1>Quasernex</h1>
        Keep Learning...
        <div className="house-animation-area">
          <HouseAnimation />
        </div>
      </div>
      <div className="home_section_two">
        <ProjectsSection />
        <Link to="/projects" className="hm-prj-view-all-btn">
          View All Projects
        </Link>
      </div>
      <div className="home_section_three">
        <MyServicesSection />
        <Link to="/about" className="hm-contactus-btn">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default Home;
