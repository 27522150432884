import React from 'react';

const HouseAnimation = () => {
  return (<>
    <div className="line"></div>
    <div className="line2"></div>
    </>
  );
};

export default HouseAnimation;
