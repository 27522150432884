import React, { useState, useEffect } from 'react';


const Project1 = (project) => {
  const [projectText, setProjectText] = useState(null);
  

  useEffect(() => {
    const fetchProjectText = async () => {
      try {
        const module = await import('../textmaterials/alltext.js');
        setProjectText(module.default);
      } catch (error) {
        console.error('Error loading ProjectText:', error);
      }
    };
    
    fetchProjectText();
  }, []); 

  // Check if projectText is loaded before rendering
  if (!projectText) {
    return <div>Loading...</div>;
  }
  
  const txtkey = project.project
  const projecttext = projectText[txtkey]
  
  return <>
      <div className='prj-midsec-1'>
      <h2>{projecttext.title}</h2>
        <section>
          <img src={require('../extras/Project1/Project1e1.jpg')} alt='prj-mid-extra' />
          <article>{projecttext.para1}</article>
        </section>
        <section>
          <img src={require('../extras/Project1/Project1e2.jpg')} alt='prj-mid-extra' />
          <article>{projecttext.para2}</article>
        </section>
        <section>
          <img src={require('../extras/Project1/Project1e2.jpg')} alt='prj-mid-extra' />
          <article>{projecttext.para3}</article>
        </section>
      </div>
  </>;
};

export default Project1;
