import React, { useState, useEffect } from 'react';


const Project1 = (project) => {
  const [projectText, setProjectText] = useState(null);
  

  useEffect(() => {
    const fetchProjectText = async () => {
      try {
        const module = await import('../textmaterials/alltext.js');
        setProjectText(module.default);
      } catch (error) {
        console.error('Error loading ProjectText:', error);
      }
    };
    
    fetchProjectText();
  }, []); 

  // Check if projectText is loaded before rendering
  if (!projectText) {
    return <div>Loading...</div>;
  }
  
  const txtkey = project.project
  const projecttext = projectText[txtkey]
  
  return <>
    <div className='prj-midsec-1'>
      <h2>{projecttext.title}</h2>
      <section>   {/*  para1,2,3 & code1,2,3 */}
        <article>{projecttext.para1}</article>
        
        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e1.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <article>{projecttext.para2}</article>
        
        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e2.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <article>{projecttext.para3}</article>
        
        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e3.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>The Admin User</p>
        <div class="code-container">
          <code>{projecttext.code1}</code>
        </div>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>The User</p>
        <div class="code-container">
          <code>{projecttext.code2}</code>
        </div>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>The Book</p>
        <div class="code-container">
          <code>{projecttext.code3}</code>
        </div>
      </section>

      <section>
        <h3 style={{textAlign:"left"}}>Create a database in Postgres and create repository classes</h3>
        <article>{projecttext.para4}</article>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e4.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>Next, simple repository classes are created like this because we have JPARepository already included in the dependencies</p>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e5.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e6.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e7.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>A couple of exception classes have been written to serve as responses when we cant get a valid response</p>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e8.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e9.jpg')} alt='prj-mid-extra' />
      </section>

      <section>
        <h3 style={{textAlign:"left"}}>Write the service classes</h3>
        <article>{projecttext.para5}</article>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e10.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <img src={require('../extras/Project1/Project1e11.jpg')} alt='prj-mid-extra' />

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>AdminUserService implementation</p>
        <div class="code-container">
          <code>{projecttext.code4}</code>
        </div>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>UserService implementation</p>
        <div class="code-container">
          <code>{projecttext.code5}</code>
        </div>
      </section>

      <section>
        <h3 style={{textAlign:"left"}}>Writing the RestController</h3>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <article>{projecttext.para6}</article>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <p style={{textAlign:"left"}}>The RestController</p>
        <div class="code-container">
          <code>{projecttext.code6}</code>
        </div>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <article>{projecttext.para7}</article>
      </section>


      <section>
        <h3 style={{textAlign:"left"}}>Conclusion</h3>

        <div className="divider" style={{ margin: "20px auto"}}></div>
        <article>{projecttext.para8}</article>
      </section>

    </div>
  </>;
};

export default Project1;
