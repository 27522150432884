import React from 'react';
import {MyServicesSectionAbt} from '../othercomponents/myservices';

const About = () => {
  const myImgpth = require('../genassets/disp-img.jpg')
  return (
    <div className="about-page">
      {/* Section 1: Introduction */}
      <div className="abtsec-intro">
        <div className="abtsec-introimg">
          <img src={myImgpth} alt='myshowview'/>
        </div>
        <div className="abtsec-introtxt">
          <h2>About Me</h2>
          <p>I am Charles, I hold a Bachelors Degree in Electrical Electronics Engineering
            and studying a masters degree in Information Science and Computer Engineering
            in St Petersburg Polytech University in Russia.
            I enjoy analysing data and building machine learning models using popular
            frameworks and libraries. I have been in the IT space since 2022.
          </p>
        </div>
      </div>

      {/* Section 2: Services */}
      <div className="abtsec-svcs">
        <MyServicesSectionAbt />
      </div>

      {/* Section 3: Contact Information */}
      <div className="abtsec-contact">
        <div className="contact-column">
          <h3>Quasernex</h3>
          <p>Address: Enugu, Nigeria</p>
        </div>
        <div className="contact-column">
          <h3>Contact Us</h3>
          <p>Write us an email: spetrs4000@gmail.com</p>
          <p>Twitter: @CharleyXclusive</p>
        </div>
      </div>

    </div>
  );
};

export default About;
