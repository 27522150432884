import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './othercomponents/Nav';
import Home from './otherpages/home';
import About from './otherpages/about';
import Allprojects from './otherpages/allprojects';
import Footer from './othercomponents/footer'
import './App.css'

const projectContext = require.context('./projects', false, /\.js$/);
const projects = projectContext.keys().map((key) => key.replace(/^\.\/(.*)\.\w+$/, '$1'));

const App = () => {
  return (
    <Router>
        <div className='nav-comp'>
          <Nav projects={projects} />
        </div>
        <div className='main-content'>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {projects.map((project) => (
              <Route
                key={project}
                path={`/${project.toLowerCase()}`}
                element={<React.Suspense fallback={<div>Loading...</div>}>
                  {React.createElement(React.lazy(() => import(`./projects/${project}`)), {project})}
                </React.Suspense>}
                
              />
            ))}

            <Route path="/" element={ <Home /> } />
            <Route path="/home" element={ <Home /> } />
            <Route path="/projects" element={ <Allprojects projects={projects} /> } />
            <Route path="/about" element={ <About /> } />
          </Routes>
        </React.Suspense>
        </div>
        <Footer />
    </Router>
  );
};

export default App;
