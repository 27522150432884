import React from 'react';
import { Link } from 'react-router-dom';

const Allprojects = ({projects}) => {
  return (<>
  <div className="all-projects-container">
    <h2> Explore All projects</h2>

    <ul className="projects-list">
      {projects.map((project) => (
        <li key={project}>
          <Link to={`/${project.toLowerCase()}`}>
            {project}
          </Link>
        </li>
        
      ))}
    </ul>

  </div>
  </>
  );
};

export default Allprojects;
